import React from 'react';
import logo from './logo.png';
import Router from "./Router";

function App() {

  return (
    <div className="bg-gray-300 flex justify-center h-screen">
      <div className="bg-white flex flex-col self-center rounded-lg lg-w-4/12">
          <div className="flex w-full">
              <img src={logo} alt="Logo" id="logo" className="mx-auto mt-5"/>
          </div>
          <Router/>
      </div>
    </div>
  );
}

export default App;
