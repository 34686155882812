import React, {Component} from 'react';
import {NNAPI} from "./NNAPI";

class InfoLead extends Component {

    constructor(props) {
        super(props);
        this.state = {
            lead: null
        }
    }

    componentDidMount() {
        if(this.props.location.search !== ""){
            NNAPI.post("/leads_payant/"+this.props.match.params.id, {paiement: this.props.location.search.split('&')[0].split('=')[1]})
                .then(response => {
                    this.setState({lead: response.data})
                    this.props.history.push(`/${this.props.match.params.id}/info`)
                })
        }else{
            NNAPI.get("/leads_payant/"+this.props.match.params.id).then(response => {
                this.setState({lead: response.data})
            })
        }
    }

    render() {
        const {lead} = this.state


        return lead === null ? null : (
            <div className="flex flex-col mx-5 lg-mx-20 my-5">
                <h2 className="text-lg font-bold mb-2">Information sur le contact :</h2>
                <p><span className="font-bold">Nom</span>: {lead.nom}</p>
                <p><span className="font-bold">Numéro de téléphone</span>: {lead.telephone} </p>
                <p><span className="font-bold">Catégorie</span>: {lead.category.category_name}</p>
                <p><span className="font-bold">Montant</span>: {lead.montant} €</p>
                <p className="mb-2"><span className="font-bold ">Commentaire</span>: {lead.information}</p>

                <p className="text-center text-gray-500 font-light italic">Tout ces informations vous ont été envoyé par email.</p>

                <p className="mt-20">Pour augmenter vos aquisitions :</p>
                <a
                    className="bg-blue-600 rounded py-2 px-3 text-center text-white font-bold my-2"
                    href={process.env.REACT_APP_SITE_WEB+"/demande_infos/new?id="+lead.attribution.id}
                    target="_blank"
                    rel="noreferrer"
                >
                    Découvrir notre service
                </a>
            </div>
        )
    }
}

export default InfoLead;
