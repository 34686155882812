import React, {Component} from 'react';
import {NNAPI} from "./NNAPI";
import axios from "axios";


class Payant extends Component {

    constructor(props) {
        super(props);
        this.state = {
            options: null,
            lead: null,
            numero: false
        }

        this.contact = this.contact.bind(this)
    }

    componentDidMount() {
        NNAPI.get("/leads_payant/"+this.props.match.params.id).then(response => {
            this.setState({lead: response.data})
        }).catch()

    }

    contact(){

        const data = {
            "blocks": [
                {
                    "type": "section",
                    "text": {
                        "type": "mrkdwn",
                        "text": "*Nouvelle demande d'informartion*"
                    }
                },
                {
                    "type": "section",
                    "text": {
                        "type": "mrkdwn",
                        "text": "*Information notaire* \n Maitre " + this.state.lead.attribution.nom
                    }
                },
                {
                    "type": "section",
                    "text": {
                        "type": "mrkdwn",
                        "text": "*Information contact* \n Nom : "+ this.state.lead.nom +"  \n Catégorie: "+ this.state.lead.category.category_name +"\n Montant: "+ this.state.lead.montant +"\n Commentaire: "+ this.state.lead.information +"\n Numéro téléphone: "+ this.state.lead.telephone +" "
                    }
                }
            ]
        }

        axios.post(process.env.REACT_APP_WEBHOOK, JSON.stringify(data),
        ).then();

        this.setState({numero:true})
    }

    render() {

        const {lead} = this.state


        return lead === null ? null : (
            <div className="flex flex-col mx-5 lg-mx-20 my-5">
                <p className="text-center flex">Vous venez de recevoir une demande de contact de la part M ou Mme {lead.nom[0]}****</p>
                <p className="mt-2"><span className="font-bold">Catégorie</span>: {lead.category.category_name}</p>
                {lead.montant == null ? "" : <p><span className="font-bold">Montant</span>: {lead.montant} €</p>}
                {lead.information === "" ? "" :<p className="mb-2"><span className="font-bold ">Commentaire</span>: {lead.information}</p>}
                <p className="">Pour obtenir les coordonnées vérifiées <span className="border-2 rounded-full px-2 border-blue-500 text-blue-500 font-bold bg-blue-50">i</span> :</p>
                <div className="flex flex-col mt-5">
                    {this.state.numero === false ? <a
                            className="bg-blue-600 rounded py-2 px-3 text-center text-white font-bold my-2"
                            onClick={this.contact}
                        >
                            Contactez nous
                        </a> :
                        <div className="text-center">
                            <p>Contactez Bastien de notre équipe à ce numéro :</p>
                            <a className="font-bold" href="tel:0623736272">06 23 73 62 72</a>
                        </div>}
                </div>
            </div>
        )
    }
}

export default Payant;
