import React, {Component} from 'react';
import {
    BrowserRouter,
    Switch,
    Route
} from "react-router-dom";
import Payant from "./Payant";
import InfoLead from "./InfoLead";
import Error404 from "./Error404";



class Router extends Component {


    render() {

        return (
            <BrowserRouter>
                <Switch>
                    <Route exact path="/:id" component={Payant}/>
                    <Route exact path="/:id/info" component={InfoLead}/>
                    <Route path="*" component={Error404}/>
                </Switch>
            </BrowserRouter>

        )
    }
}

export default Router;
