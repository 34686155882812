import React, {Component} from 'react';

class Error404 extends Component {

    render() {

        return (
            <div className="flex flex-col mx-20 my-5 text-center">
                <p className="text-4xl text-red-600">Erreur ce lien n'existe pas</p>
            </div>
        )
    }
}

export default Error404;
